// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {eIRs8KpTu: {hover: true}};

const serializationHash = "framer-tuHcJ"

const variantClassNames = {eIRs8KpTu: "framer-v-eq9jos"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition2 = {bounce: 0.25, delay: 0, duration: 0.45, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.1, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({grayscale, height, id, link, logoImage, newTab, opacity, width, ...props}) => { return {...props, IxGztm2_B: newTab ?? props.IxGztm2_B ?? true, MSW0Dcr8c: opacity ?? props.MSW0Dcr8c ?? 1, RBSiZ3NEJ: grayscale ?? props.RBSiZ3NEJ ?? 100, Y72B60Rbq: logoImage ?? props.Y72B60Rbq ?? {alt: "", src: "https://framerusercontent.com/images/2hBWlu8mRz0AjMMNnLm6B2dPTE.webp"}, YyV1GHx6Y: link ?? props.YyV1GHx6Y} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;logoImage?: {src: string; srcSet?: string; alt?: string};link?: string;grayscale?: number;opacity?: number;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, Y72B60Rbq, YyV1GHx6Y, RBSiZ3NEJ, MSW0Dcr8c, IxGztm2_B, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "eIRs8KpTu", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-eq9jos", className, classNames)} data-framer-name={"Logos"} layoutDependency={layoutDependency} layoutId={"eIRs8KpTu"} ref={refBinding} style={{opacity: MSW0Dcr8c, ...style}} {...addPropertyOverrides({"eIRs8KpTu-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Link href={YyV1GHx6Y} motionChild nodeId={"mOav6juxI"} openInNewTab={IxGztm2_B} scopeId={"KXWb9fDF6"} smoothScroll><Image as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 42, intrinsicWidth: 250, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 47) - 0) - 47) / 2) + 0 + 0))), pixelHeight: 84, pixelWidth: 500, sizes: "283px", ...toResponsiveImage(Y72B60Rbq), ...{ positionX: "center", positionY: "center" }}} className={"framer-qqhf5e framer-6cv5cf"} data-framer-name={"Money Tech"} layoutDependency={layoutDependency} layoutId={"mOav6juxI"} style={{filter: `grayscale(${parseFloat(RBSiZ3NEJ)/100})`, WebkitFilter: `grayscale(${parseFloat(RBSiZ3NEJ)/100})`}} variants={{"eIRs8KpTu-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} whileHover={animation}/></Link></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tuHcJ.framer-6cv5cf, .framer-tuHcJ .framer-6cv5cf { display: block; }", ".framer-tuHcJ.framer-eq9jos { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-tuHcJ .framer-qqhf5e { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; height: 47px; justify-content: space-around; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 283px; will-change: var(--framer-will-change-effect-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tuHcJ.framer-eq9jos { gap: 0px; } .framer-tuHcJ.framer-eq9jos > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-tuHcJ.framer-eq9jos > :first-child { margin-top: 0px; } .framer-tuHcJ.framer-eq9jos > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 283
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ieeg9bj5P":{"layout":["auto","auto"]}}}
 * @framerVariables {"Y72B60Rbq":"logoImage","YyV1GHx6Y":"link","RBSiZ3NEJ":"grayscale","MSW0Dcr8c":"opacity","IxGztm2_B":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKXWb9fDF6: React.ComponentType<Props> = withCSS(Component, css, "framer-tuHcJ") as typeof Component;
export default FramerKXWb9fDF6;

FramerKXWb9fDF6.displayName = "5";

FramerKXWb9fDF6.defaultProps = {height: 47, width: 283};

addPropertyControls(FramerKXWb9fDF6, {Y72B60Rbq: {__defaultAssetReference: "data:framer/asset-reference,2hBWlu8mRz0AjMMNnLm6B2dPTE.webp?originalFilename=671a4450c81693f1d7a33b9b_633358afe35e01a6f16574b1_moneytoday-logo-p-500.webp&preferredSize=auto", __vekterDefault: {alt: "", assetReference: "data:framer/asset-reference,2hBWlu8mRz0AjMMNnLm6B2dPTE.webp?originalFilename=671a4450c81693f1d7a33b9b_633358afe35e01a6f16574b1_moneytoday-logo-p-500.webp&preferredSize=auto"}, title: "Logo Image", type: ControlType.ResponsiveImage}, YyV1GHx6Y: {title: "Link", type: ControlType.Link}, RBSiZ3NEJ: {defaultValue: 100, title: "Grayscale", type: ControlType.Number}, MSW0Dcr8c: {defaultValue: 1, max: 1, min: 0, step: 0.01, title: "Opacity", type: ControlType.Number}, IxGztm2_B: {defaultValue: true, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerKXWb9fDF6, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})